<template>
  <div>
    <v-img
      height="300px"
      class="white--text text-center align-end"
      position="center center"
      :src="currentPlay.carousel || currentPlay.img"
      gradient="to top, rgba(255,255,255,0), rgba(0,0,0,.3), rgba(255,255,255,0)"
    >
      <v-container>
        <p class="mt-5 mb-0 text-h6 text-center">
          {{currentPlay.company || 'Audiodramapp'}} presenta:
        </p>
        <p class="text-h4 mb-2 text-md-h3 text-center font-weight-bold text-uppercase">
          {{ currentPlay.name }}
        </p>
        <v-chip small color="cyan darken-2" v-if="currentPlay.release_date && currentPlay.end_date">
          Desde {{currentPlay.release_date}} al {{currentPlay.end_date}}
        </v-chip>
        <p class="text-caption text-end mt-15" v-if="currentPlay.photograph">
          <v-icon x-small class="mr-1" color="white">mdi-camera</v-icon>
          {{ currentPlay.photograph.name }}
          <a
            class="text-decoration-none white--text"
            :href="currentPlay.photograph.link"
            target="_blank"
            >
            | {{ currentPlay.photograph.portfolio }}
          </a>
        </p>
        <p class="text-caption text-end mt-15" v-else>
          <v-icon x-small class="mr-1" color="white">mdi-camera</v-icon>{{currentPlay.company}}
        </p>
      </v-container>
    </v-img>
  </div>
</template>

<script>
export default {
  props: ['currentPlay']
}
</script>