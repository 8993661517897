import { newCart } from './emptyObjects'

// Saves items on storage, 
// users and shopping cart items
function setInStorage(key, obj) {
  localStorage.setItem(key, JSON.stringify(obj))
}
// Calculates shopping cart total
function getCartTotal(productsList) {
  let price = 0.0
  productsList.forEach(p => {
    price += p.price
  })
  return price
}

export default {
  // Toggles loading state
  SHOW_LOADING(state) { state.loading = true },
  HIDE_LOADING(state) { state.loading = false },
  // Sets current play info into state
  SET_CURRENT_PLAY(state, play) { state.currentPlay = play },
  // Saves user information
  UPDATE_CURRENT_USER(state, user) {
    state.currentUser = user
  },
  UPDATE_ALERT(state, alert) {
    state.alert.state = alert.state
    state.alert.text = alert.text
  },
  // Adds item to cart when selected 
  ADD_TO_CART(state, product) {
    // Adds default quantity if not defined
    if (product.qty === undefined) { product['qty'] = 1 }
    // Increases quantity if product already exists on cart, else add to cart as new
    let pidx = state.shoppingCart.list.map(p => p.id).indexOf(product.id)
    if (pidx >= 0) {
      state.shoppingCart.list[pidx].qty++
    } else {
      state.shoppingCart.list.push(product)
    }
    // Updates total price
    state.shoppingCart.total = getCartTotal(state.shoppingCart.list)
    // Updates cart on storage
    setInStorage('cart', state.shoppingCart)
  },
  // Removes selected item from shopping cart
  REMOVE_FROM_CART(state, product_id) {
    // Finds on cart doll by id
    let pidx = state.shoppingCart.list.map(p => p.id).indexOf(product_id)
    if (pidx >= 0) {
      // Deletes selected item 
      state.shoppingCart.list.splice(pidx, 1)
      // Updates total price
      state.shoppingCart.total = getCartTotal(state.shoppingCart.list)
      // Updates in storage
      setInStorage('cart', state.shoppingCart)
    } else {
      throw new Error("Product not found in cart")
    }
  },
  // Resets shopping cart to empty
  CLEAR_CART(state) {
    state.shoppingCart = newCart()
    setInStorage('cart', state.shoppingCart)
  },
  // Toggles shopping cart display
  UPDATE_SHOW_CART(state, value) {
    state.showCart = value
  }
}