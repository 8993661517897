import { firestoreAction } from "vuexfire";
import {
	plays,
	playChapters,
	Auth,
	FireDB,
	userChapters,
	Axn,
} from "../firebase";
import cartActions from "./actions/cart_actions";
import router from "../router";

function generateFullCart(uid, email, fullCart) {
	return {
		flowParams: {
			commerceOrder: Math.round(Math.random() * 1000000),
			subject: `Compra Audiodramapp`,
			currency: "CLP",
			amount: fullCart.total,
			email: email,
			optional: JSON.stringify({ uid: uid }),
		},
		cart: fullCart,
	};
}

export default {
	getPlays: firestoreAction(({ bindFirestoreRef }) => {
		return bindFirestoreRef("plays", plays);
	}),
	getUserChapters: firestoreAction(({ bindFirestoreRef }, userId) => {
		return bindFirestoreRef("userChapters", userChapters(userId));
	}),
	getPlayChapters: firestoreAction(({ bindFirestoreRef }, playId) => {
		return bindFirestoreRef("playChapters", playChapters(playId));
	}),
	...cartActions,
	saveContent({ getters, commit }) {
		const email = getters.currentUser.email;
		const uid = getters.currentUser.uid;
		const cart = getters.cartDetails;
		const fullCart = generateFullCart(uid, email, cart);
		var initPayment = Axn.httpsCallable("initPayment");
		initPayment(fullCart)
			.then(function (result) {
				commit("UPDATE_ALERT", {
					state: true,
					text:
						"Serás redirigido al recaudador. Automáticamente volverás a Audiodramapp al terminar. El medio de pago se puede demorar unos segundos en regresarte",
				});
				setTimeout(() => {
					window.location.href = `${result.data.url}?token=${result.data.token}`;
					commit("CLEAR_CART");
				}, 5000);
			})
			.catch(function (error) {
				commit("UPDATE_ALERT", { state: true, text: error });
			});
	},
	//Update alert
	updateAlert({ commit }, alert) {
		return new Promise((resolve, reject) => {
			try {
				commit("UPDATE_ALERT", alert);
				resolve(true);
			} catch (e) {
				reject(e);
			}
		});
	},
	logIn({ commit }, { email, password }) {
		if (email != "" && password != "") {
			Auth.signInWithEmailAndPassword(email, password)
				.then((response) => {
					//Create the userContent doc if it not exists
					FireDB.collection("userContent")
						.doc(response.user.uid)
						.set({}, { merge: true });
					commit("UPDATE_ALERT", {
						state: true,
						text: `Bienvenido, ${response.user.email}`,
					});
					setTimeout(() => {
						router.push("/mis-obras");
					}, 1500);
				})
				.catch((error) => {
					commit("UPDATE_ALERT", {
						state: true,
						text: errorMeggage(error.code),
					});
				});
		} else {
			let text = "Todos los campos son obligatorios.";
			commit("UPDATE_ALERT", { state: true, text: text });
		}
	},
	signUp({ commit }, { email, password }) {
		if (email != "" && password != "") {
			Auth.createUserWithEmailAndPassword(email, password)
				.then((response) => {
					FireDB.collection("userContent")
						.doc(response.user.uid)
						.set({}); // Create empty userContent doc on the db
					commit("UPDATE_ALERT", {
						state: true,
						text: `Bienvenido, ${response.user.email}`,
					});
					setTimeout(() => {
						router.push("/");
					}, 1500);
				})
				.catch((error) => {
					commit("UPDATE_ALERT", {
						state: true,
						text: errorMeggage(error.code),
					});
				});
		} else {
			commit("UPDATE_ALERT", {
				state: true,
				text: "Todos los campos son obligatorios.",
			});
		}
	},
	logout({ commit }) {
		Auth.signOut()
			.then(() => {
				commit("UPDATE_ALERT", {
					state: true,
					text: `Sesión cerrada con éxito`,
				});
				setTimeout(() => {
					router.push("/");
				}, 1500);
			})
			.catch((error) => {
				commit("UPDATE_ALERT", { state: true, text: error.message });
			});
	},
	authState({ commit }) {
		Auth.onAuthStateChanged((user) => {
			if (user) {
				commit("UPDATE_CURRENT_USER", user);
			} else {
				commit("UPDATE_CURRENT_USER", null);
			}
		});
	},
	resetPassword({ commit }, email) {
		Auth.sendPasswordResetEmail(email)
			.then(() => {
				commit("UPDATE_ALERT", { state: true, text: `Correo enviado` });
			})
			.catch((error) => {
				commit("UPDATE_ALERT", { state: true, text: error.message });
			});
	},
};

// TODO: EXTRACT TO HELPER FILE
function errorMeggage(code) {
	switch (code) {
		case "auth/user-not-found":
			return "Usuario no encontrado, ¡regístrate!";
		case "auth/invalid-email":
			return "¡Ups! El correo es inválido.";
		case "auth/email-already-in-use":
			return "Este usuario ya está registrado, ¡inicia sesión!";
		case "auth/wrong-password":
			return "Usuario o contraseña incorrectos. Intente nuevamente.";
		default:
			return "¡Ups! Hubo un error, intente más tarde";
	}
}
