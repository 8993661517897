<template>
  <!-- Alert on errors and success -->
  <v-snackbar
    elevation="0"
    top
    color="cyan darken-2"
    v-model="alert.state"
    timeout="5000"
  >
    {{ alert.text }}
    <template v-slot:action="{ attrs }">
      <!-- To close alert -->
      <v-btn icon small color="white" v-bind="attrs" @click="clearAlert">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: mapState(["alert"]),
  methods: {
    ...mapActions(["updateAlert"]),
    clearAlert() {
      let alert = { state: false, text: "" };
      this.updateAlert(alert);
    },
  },
};
</script>

<style></style>
