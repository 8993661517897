<template>
  <v-footer absolute padless class="text-center text-caption transparent" :style="routeCheck ? '' : 'display: none'">
    <v-row no-gutters align="center" class="my-2">
      <v-col cols='12' sm="6" class="mb-2">
        <p class="mb-0">Soporte: <a href="mailto:contacto@audiodramapp.cl" class="font-weight-bold cyan--text text--darken-2 text-decoration-none">contacto@audiodramapp.cl</a></p>
      </v-col>
      <v-col cols='12' sm="6">
        <v-img src="../assets/img/logoNoSubheading.png" width="100px" style="display: inline-block"></v-img>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    routeCheck: true
  }),
  watch: {
    '$route'(){
      if(this.$route.path == '/ingresa'){
        this.routeCheck = false
      } else{
        this.routeCheck = true
      }
    }
  }
}
</script>