<template>
  <v-main class="mb-10">
    <hero-section/>
    <v-container>
      <play-cards/>
    </v-container>
  </v-main>
</template>

<script>
import HeroSection from '../components/HeroSection'
import PlayCards from '../components/PlaysCards'

export default {
  name: 'Home',
  components: {
    HeroSection,
    PlayCards
  },
  created(){
    window.scrollTo({ top: 0});
  }
}
</script>
