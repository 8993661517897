<template>
  <v-main>
    <!-- Carousel -->
    <v-carousel hide-delimiters :show-arrows="false" cycle height="100vh" class="align-center" interval="3000">
      <!-- Logo -->
      <v-toolbar absolute flat bottom class="ma-auto" height="400px" width="100%" color="transparent" rounded="xl">
        <a class="text-center mt-auto mx-auto" width="100vw" @click="goToPlays">
          <v-img src="../assets/img/logoFull.png" class="mx-auto" width="70vw" max-width="700px"></v-img>
          <v-btn icon rounded color="white" class="mt-8 mb-2" @click="goToPlays"><v-icon>mdi-chevron-double-down</v-icon></v-btn>
        </a>
      </v-toolbar>
      <!-- Carrousel images -->
      <v-carousel-item v-for="(img, i) in images" :key="i" :src="url+img" position="center bottom" gradient="to top, rgba(0,0,0,.2), rgba(0,0,0,0), rgba(0,0,0,.2)">
      </v-carousel-item>
    </v-carousel>
  </v-main>
</template>

<script>
export default {
  data: () => ({
    url: 'https://firebasestorage.googleapis.com/v0/b/legionescenica.appspot.com/o/herosection%2F',
    images: [
      'carousel-08.jpg?alt=media&token=64796439-7186-44d2-9b54-879aa257f9f5',
      'carousel-05.jpg?alt=media&token=f3fc2abd-aca5-4364-9f02-caafba170f4b',
      'carousel-01.jpg?alt=media&token=0ca1bb3e-597f-471d-927b-d6f62d746353',
      'carousel-06.jpg?alt=media&token=d49b9800-5a81-408d-a3dd-4d3c8476ab00',
      'carousel-02.jpg?alt=media&token=2793d336-f77c-4b75-95de-54a95811687a',
      'carousel-09.jpg?alt=media&token=a84064fc-f861-4514-8933-fdd1c54ff2be'
      ]
  }),
  methods: {
    goToPlays(){
      const element = document.getElementById('plays');
      element.scrollIntoView({ behavior: 'smooth' });
    }
  },
}
</script>

<style scoped>
.carousel-title{
  font-size: 35px !important;
  line-height: 1;
  margin: 0;
  -webkit-text-stroke-width: 2px;
  letter-spacing: -2px !important;
}

.outline{
  -webkit-text-stroke-color: #0097A7;
}

.carousel-subtitle{
  -webkit-text-stroke-width: .8px;
}

/* On large devices */
@media (min-width: 960px) {
  .carousel-title{
    font-size: 70px !important;
    letter-spacing: -3px !important;
    -webkit-text-stroke-width: 3px;
  }
  .carousel-subtitle{
    font-size: 20px !important;
    margin-bottom: 40px;
    -webkit-text-stroke-width: 1px;
  }
}
</style>