<template>
  <v-app>
    <app-bar />
    <v-main>
      <cart-modal></cart-modal>
      <snack-bar></snack-bar>
      <router-view></router-view>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar";
import CartModal from "./components/CartModal";
import Footer from "./components/Footer";
import SnackBar from "./components/SnackBar";
import { mapActions } from "vuex";
export default {
  name: "App",
  components: {
    AppBar,
    CartModal,
    SnackBar,
    Footer
  },
  methods: mapActions(["authState"]),
  mounted() {
    this.authState();
  }
};
</script>
