export default {
	// TODO: This method is too complex. Needs unit tests and refactor
	addToCart({ commit, getters }, product) {
		if (product.isFullPlay) {
			// Remove loose chapters of the same play
			let playId = product.id;
			getters.cartDetails.list
				.filter((item) => item.playId == playId)
				.forEach((chapter) => {
					commit("REMOVE_FROM_CART", chapter.id);
				});
			// ...and add the play
			commit("ADD_TO_CART", product);
			commit("UPDATE_ALERT", { state: true, text: "Obra agregada al carro" });
		} else {
			// Product is just a chapter
			//Make sure the play is not already present in the cart
			let isPresent = getters.cartDetails.list.filter((item) => {
				return item.id == product.playId;
			});
			if (isPresent.length > 0) {
				commit("UPDATE_ALERT", {
					state: true,
					text: "Ya tienes esa obra completa en el carro",
				});
			} else {
				commit("ADD_TO_CART", product);
				commit("UPDATE_ALERT", {
					state: true,
					text: "Capítulo agregado al carro",
				});
			}
		}
	},
	// Removes selected items from cart
	removeFromCart({ commit }, product_id) {
		return new Promise((resolve, reject) => {
			try {
				commit("REMOVE_FROM_CART", product_id);
				resolve(true);
			} catch (e) {
				reject(e);
			}
		});
	},
	// Resets cart into empty
	clearCart({ commit }) {
		return new Promise((resolve, reject) => {
			try {
				commit("CLEAR_CART");
				resolve(true);
			} catch (e) {
				reject(e);
			}
		});
	},
	// Toggles and updates shopping cart modal state
	updateShowCart({ commit }, val) {
		return new Promise((resolve, reject) => {
			try {
				commit("UPDATE_SHOW_CART", !!val); // !! double-negation for Boolen casting
				resolve(true);
			} catch (e) {
				reject(e);
			}
		});
	},
};
