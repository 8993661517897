import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import SinglePlay from '../views/SinglePlay.vue'
import { Auth } from '../firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    alias: '/home',
    component: Home
  },
  {
    path: '/obra/:name',
    name: 'SinglePlay',
    component: SinglePlay
  },
  {
    path: '/ingresa',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/mis-obras',
    name: 'my-plays',
    component: () => import(/* webpackChunkName: "my-plays" */ '../views/MyPlays.vue'),
    meta: {
      login: true
    }
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let user = Auth.currentUser;
  let authRequired = to.matched.some(route => route.meta.login);
  if (!user && authRequired) {
    next('/ingresa')
  } else {
    next();
  }
})

export default router