<template>
  <!-- Shopping cart detail -->
  <v-dialog
    overlay-color="black"
    overlay-opacity="0.8"
    :value="showModal"
    max-width="500px"
    persistent
  >
    <v-card class="pa-3">
      <v-card-actions>
        <!-- Close button -->
        <v-btn icon small fab color="white" @click="showModal = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <!-- Clear cart button -->
        <v-btn
          small
          depressed
          icon
          color="cyan darken-2"
          @click="clearCart"
          v-if="cartDetails.list != ''"
        >
          <v-icon>mdi-delete-alert</v-icon>
        </v-btn>
      </v-card-actions>
      <!-- Header -->
      <v-card-title class="justify-center pt-0">
        <span class="text-body-1 text-md-h6">
          {{
            cartDetails.list != ""
              ? "Capítulos seleccionados:"
              : "Sin obras seleccionadas."
          }}
        </span>
      </v-card-title>
      <!-- Input to update data -->
      <v-card-text class="mt-4 pb-0 white--text" v-if="cartDetails.list != ''">
        <v-divider class="mb-5"></v-divider>
        <!-- Creates row for every product on cart -->
        <div v-for="item in cartObjects" :key="item.id">
          <v-row dense align="center" class="text-right text-sm-center">
            <!-- Remove specific item from shopping cart -->
            <v-col cols="2" sm="1">
              <v-btn
                icon
                x-small
                color="white"
                @click="removeFromCart(item.id)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
            <!-- Product image -->
            <v-col cols="10" sm="8" class="font-weight-bold">
              <p class="mb-0">Capítulo: {{ item.chapter }}</p>
              <span class="text-caption">{{ item.title }}</span>
            </v-col>
            <!-- Product total price -->
            <v-col cols="12" sm="3">
              <span>$ {{ item.price | toCurrency }}</span>
            </v-col>
          </v-row>
          <v-divider class="my-5"></v-divider>
        </div>
        <!-- Total -->
        <p class="text-h6 text-right">
          <span class="text-subtitle-1 mr-2">Total:</span>
          $ {{ cartDetails.total | toCurrency }}
        </p>
      </v-card-text>
      <!-- Buttons to save product or close form -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="white"
          class="px-3"
          small
          rounded
          outlined
          @click="showModal = false"
          >Volver</v-btn
        >
        <v-btn
          color="cyan darken-2"
          class="px-3"
          small
          rounded
          depressed
          dark
          v-if="cartDetails.list != ''"
          @click="purchase"
          >Finalizar compra</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  methods: {
    ...mapActions([
      "updateShowCart",
      "saveContent",
      "removeFromCart",
      "clearCart",
    ]),
    purchase() {
      if (!this.isUserAuth) {
        this.$router.push("/ingresa");
        this.updateShowCart(false);
      } else {
        this.saveContent();
        this.showModal = false;
      }
    },
  },
  computed: {
    ...mapGetters(["showCart", "cartDetails", "isUserAuth", "cartObjects"]),
    showModal: {
      get() {
        return this.showCart;
      },
      set(val) {
        this.updateShowCart(val);
      },
    },
  },
};
</script>
