import Firebase from "firebase/app";
require("firebase/auth");
require("firebase/firestore");
require("firebase/functions");
require("firebase/analytics");

let config = {
  apiKey:
    process.env.VUE_APP_API_KEY || "AIzaSyCH2B-e6Dif81ZVhu-cq3dMHR1oJ-BlzeU",
  authDomain:
    process.env.VUE_APP_AUTH_DOMAIN || "legionescenica.firebaseapp.com",
  databaseURL:
    process.env.VUE_APP_DATABASE_URL || "https://legionescenica.firebaseio.com",
  projectId: process.env.VUE_APP_PROJECT_ID || "legionescenica",
  storageBucket:
    process.env.VUE_APP_STORAGE_BUCKET || "legionescenica.appspot.com",
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID || "402564122071",
  appId:
    process.env.VUE_APP_APP_ID || "1:402564122071:web:c02149906f61caebe23792",
};

const FireBaseApp = Firebase.initializeApp(config);
//FireBaseApp.analytics();
const Auth = FireBaseApp.auth();
const Axn = FireBaseApp.functions();
const FireDB = FireBaseApp.firestore();

if (location.hostname === "localhost") {
  FireDB.settings({
    host: "localhost:8080",
    ssl: false,
  });
  Axn.useFunctionsEmulator("http://localhost:5001");
}

const plays = FireDB.collection("plays");

const playChapters = function (playId) {
  return FireDB.collection("plays")
    .doc(playId)
    .collection("chapters");
};

const userChapters = function (uid) {
  return FireDB.collection("userContent")
    .doc(uid)
    .collection("chapters");
};

export { playChapters, userChapters, plays, FireDB, Auth, Axn };
