<template>
  <div>
    <v-app-bar
      :color="AppBarColor"
      dark
      fixed
      class="px-2 px-md-10"
      elevate-on-scroll
    >
      <!-- Brand name, redirects to home -->
      <a @click="clickHome">
        <v-img contain width="50" src="../assets/img/logoIsotipo.png"></v-img>
      </a>
      <v-spacer></v-spacer>
      <!-- Menu on large devices -->
      <div class="d-none d-md-flex mt-1">
        <div class="mr-2">
          <v-btn depressed icon @click="showCart">
            <!-- Shopping cart icon -->
            <v-badge
              :content="cartObjects.length"
              :value="cartObjects.length > 0 ? cartObjects.length : ''"
              color="cyan darken-2"
              overlap
            >
              <v-icon medium color="white">mdi-shopping-music</v-icon>
            </v-badge>
          </v-btn>
        </div>
        <!-- If it's not logged in -->
        <div v-if="!!currentUser" class="my-auto">
          <!-- Purchased plays page -->
          <v-btn rounded small text to="/mis-obras">
            Mis obras
          </v-btn>
          <!-- Logout -->
          <v-btn rounded small text @click="logout">
            Cerrar sesión
          </v-btn>
        </div>
        <!-- If it's logged in -->
        <div v-else class="my-auto">
          <v-btn rounded small text to="/ingresa">
            Mi cuenta
          </v-btn>
        </div>
      </div>
      <!-- Menu on small devices -->
      <v-menu rounded>
        <!-- Button activator  -->
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            class="d-md-none white--text"
            icon
            v-bind="attrs"
            v-on="on"
            depressed
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <!-- Dropdown list items -->
        <v-list flat elevation="0">
          <!-- Shopping cart on small devices -->
          <v-list-item @click="showCart" class="d-md-none mb-2">
            <v-list-item-title>
              <v-badge
                :content="cartObjects.length"
                :value="cartObjects.length > 0 ? cartObjects.length : ''"
                color="cyan darken-2"
                overlap
              >
                <v-icon medium class="ml-2" color="white"
                  >mdi-shopping-music</v-icon
                >
              </v-badge>
              <span class="ml-2">Ver compras</span>
            </v-list-item-title>
          </v-list-item>
          <!-- Only if it's logged in -->
          <div v-if="!!currentUser">
            <!-- Purchased plays -->
            <v-list-item link to="/mis-obras">
              <v-list-item-title>
                <v-icon medium class="mx-2" color="white">mdi-theater</v-icon>
                Mis obras
              </v-list-item-title>
            </v-list-item>
            <!-- Logout -->
            <v-list-item link @click="logout">
              <v-list-item-title>
                <v-icon medium class="mx-2" color="white"
                  >mdi-account-cancel</v-icon
                >
                Cerrar sesión
              </v-list-item-title>
            </v-list-item>
          </div>
          <!-- Only if it's not logged in -->
          <div v-else>
            <!-- Log in -->
            <v-list-item link to="/ingresa">
              <v-list-item-title>
                <v-icon medium class="mx-2" color="white"
                  >mdi-account-arrow-right</v-icon
                >
                Mi cuenta
              </v-list-item-title>
            </v-list-item>
          </div>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    AppBarColor: "transparent",
  }),
  methods: {
    // Show modal cart
    showCart(event) {
      event.preventDefault();
      this.updateShowCart(true);
    },
    doLogout() {
      this.logout();
    },
    clickHome() {
      if (this.$route.path != "/home" && this.$route.path != "/") {
        this.$router.push("/home");
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    changeAppBar() {
      if (
        document.body.scrollTop > 5 ||
        document.documentElement.scrollTop > 5
      ) {
        this.AppBarColor = "dark";
      } else {
        this.AppBarColor = "transparent";
      }
    },
    ...mapActions(["updateUser", "updateShowCart", "logout"]),
  },
  computed: {
    ...mapState(["currentUser"]),
    ...mapGetters(["cartDetails", "cartObjects"]),
  },
  created() {
    window.addEventListener("scroll", this.changeAppBar);
  },
};
</script>