<template>
  <div>
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="6"
        class="px-2"
        v-if="credits.direction"
      >
        <p class="text-caption font-weight-bold text-left text-sm-right mb-0">
          Dirección y adaptación:
        </p>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="px-2"
        v-if="credits.direction"
      >
        <p class="text-caption text-left mb-2 mb-sm-0">
          {{ credits.direction }}
        </p>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="px-2"
        v-if="credits.script"
      >
        <p class="text-caption font-weight-bold text-left text-sm-right mb-0">
          Guión original:
        </p>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="px-2"
        v-if="credits.script"
      >
        <p class="text-caption text-left mb-2 mb-sm-0">
          {{ credits.script }}
        </p>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="px-2"
        v-if="credits.author"
      >
        <p class="text-caption font-weight-bold text-left text-sm-right mb-0">
          Guión original y dirección:
        </p>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="px-2"
        v-if="credits.author"
      >
        <p class="text-caption text-left mb-2 mb-sm-0">
          {{ credits.author }}
        </p>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="px-2"
        v-if="credits.voices"
      >
        <p class="text-caption font-weight-bold text-left text-sm-right mb-0">
          Con la voz de:
        </p>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="px-2"
        v-if="credits.voices"
      >
        <p class="text-caption text-justify mb-2 mb-sm-0">
          {{ credits.voices }}
        </p>
      </v-col>
      <v-col cols="12" sm="6" class="px-2" v-if="credits.sound">
        <p
          class="text-caption font-weight-bold text-left text-sm-right mb-0"
        >
          Universo y edición de sonido:
        </p>
      </v-col>
      <v-col cols="12" sm="6" class="px-2" v-if="credits.sound">
        <p class="text-caption text-left mb-2 mb-sm-0">
          {{ credits.sound }}
        </p>
      </v-col>
      <v-col cols="12" sm="6" class="px-2" v-if="credits.sound_universe">
        <p
          class="text-caption font-weight-bold text-left text-sm-right mb-0"
        >
          Universo sonoro:
        </p>
      </v-col>
      <v-col cols="12" sm="6" class="px-2" v-if="credits.sound_universe">
        <p class="text-caption text-left mb-2 mb-sm-0">
          {{ credits.sound_universe }}
        </p>
      </v-col>
      <v-col cols="12" sm="6" class="px-2" v-if="credits.sound_editing">
        <p
          class="text-caption font-weight-bold text-left text-sm-right mb-0"
        >
          Edición final:
        </p>
      </v-col>
      <v-col cols="12" sm="6" class="px-2" v-if="credits.sound_editing">
        <p class="text-caption text-left mb-2 mb-sm-0">
          {{ credits.sound_editing }}
        </p>
      </v-col>
      <v-col cols="12" sm="6" class="px-2" v-if="credits.production">
        <p
          class="text-caption font-weight-bold text-left text-sm-right mb-0"
        >
          Producción:
        </p>
      </v-col>
      <v-col cols="12" sm="6" class="px-2" v-if="credits.production">
        <p class="text-caption text-left mb-2 mb-sm-0">
          {{ credits.production }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ['credits']
}
</script>