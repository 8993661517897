import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
import mutations from "./mutations";
import { newCart } from "./emptyObjects";
import { vuexfireMutations } from "vuexfire";

Vue.use(Vuex);

// Gets items from storage (like users and shopping cart items)
function getFromStorage(key) {
	return JSON.parse(localStorage.getItem(key));
}

// There is a good reason I define my initial state in a constant outside of the store object is so we can refer to this state when we, for example, want to clear our state.Also, you might wonder why we define a function instead of an object.Well, that has to do with how variables are assigned to one another in Javascript.Objects and Arrays are assigned by reference in JavaScript.

//reference: https://levelup.gitconnected.com/firebase-auth-management-in-vue-js-with-vuex-9c4a5d9cedc

function initialState() {
	return {
		plays: [],
		currentUser: null,
		userPlays: [],
		userChapters: [],
		playChapters: [],
		loading: false,
		messageModal: false,
		shoppingCart: getFromStorage("cart") || newCart(),
		showCart: false,
		alert: {
			text: "",
			state: false,
			type: "",
		},
	};
}
export default new Vuex.Store({
	state: { ...initialState() },
	mutations: {
		...mutations,
		...vuexfireMutations,
	},
	actions,
	getters: {
		cartDetails: state => state.shoppingCart,
		showCart: (state) => state.showCart,
		currentUser: (state) => state.currentUser,
		isUserAuth: (state) => !!state.currentUser,
		cartObjects(state) {
			return state.shoppingCart.list.filter((elem) => {
				return typeof elem == "object";
			});
		},
	},
});
