<template>
  <v-main>
    <v-row class="mt-8 mb-15" id="plays">
      <!-- PLAYS FOR HOME PAGE -->
      <!-- Creates cards for every play -->
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        v-for="item in sortedPlays"
        :key="item.id"
      >
        <!-- Card -->
        <v-card
          flat
          :to="{ name: 'SinglePlay', params: { name: item.link } }"
          :disabled="item.credits ? false : true"
        >
          <v-img
            class="text-center align-center"
            height="300px"
            :src="item.img"
            :position="item.img_position || 'top'"
          >          
            <v-chip
              class="mx-2 mb-5 pa-5 font-weight-bold text-uppercase"
              color="cyan darken-2"
              text-color="white"
              label
              small
              v-if="!item.available"
            >
              {{item.release_date || 'Pronto'}}
              <v-icon small right>{{item.release_date ? 'mdi-calendar' : 'mdi-clock-outline'}}</v-icon>
            </v-chip>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    <!-- Loading -->
    <v-overlay absolute v-if="plays.length==0" opacity="0" class="my-10">
      <v-progress-circular
        indeterminate
        color="cyan darken-2"
        size="30"
      ></v-progress-circular>
    </v-overlay>
  </v-main>
</template>

<script>
// Helper function used to sort plays
function compare(a, b) {
  if (a.order < b.order) return -1;
  if (a.order > b.order) return 1;
  return 0;
}

import { mapState, mapActions } from "vuex";

export default {
  methods: mapActions(["getPlays"]),
  computed: {
    ...mapState(["plays"]),
    sortedPlays() {
      if (this.plays) {
        const sorted_plays = this.plays;
        return sorted_plays.sort(compare);
      } else {
        return null;
      }
    },
  },
  created() {
    this.getPlays();
  }
};
</script>