<template>
  <div v-if="currentPlay">
    <v-main class="mb-15">
      <play-banner :currentPlay="currentPlay"></play-banner>      
      <v-container class="mt-3">
        <play-credits :credits="currentPlay.credits"></play-credits>
        <v-card outlined class="mt-10 pa-5">
          <v-row>
            <v-col cols="12" sm="8">
              <v-card-text class="pa-0">
                <p class="pa-0 mb-0 text-caption text-md-body-2 text-uppercase font-weight-bold cyan--text text--darken-2">
                  Obra completa
                </p>
                <p class="pa-0 mb-0 text-h5 font-weight-bold text-uppercase">
                  {{ currentPlay.name }}
                </p>
                <p class="pa-0 mb-0 text-justify text-body-2">
                  {{ currentPlay.description }}
                </p>
              </v-card-text>
            </v-col>
            <v-col cols="12" sm="4" class="mt-auto">
              <v-card-text class="pa-0 text-right">
                <span class="pa-0 mb-0 mr-3 text-h6 font-weight-bold" v-if="currentPlay.free">
                  Gratis
                </span>
                <span class="pa-0 mb-0 mr-3 text-h6 font-weight-bold" v-else-if="currentPlay.totalprice">
                  $ {{ currentPlay.totalprice | toCurrency }}
                </span>
                <span class="pa-0 mb-0 mr-3 text-h6 font-weight-bold" v-else>
                  Pronto
                </span>
                <v-tooltip
                  top
                  color="cyan darken-2"
                  :disabled="currentPlay.available"
                  transition="scroll-y-reverse-transition"
                  close-delay="200"
                >
                  <template v-slot:activator="{ on }">
                    <div v-on="on" style="display: inline-block;">
                      <v-btn
                        small
                        text
                        color="cyan darken-2"
                        @click="addFullPlay"
                        :disabled="!currentPlay.available || currentPlay.free"
                      >
                        <span class="d-sm-none mr-1">Comprar</span>
                        <v-icon class="mb-md-1">mdi-ticket</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>{{
                    currentPlay.release_date
                      ? "Desde el " + currentPlay.release_date
                      : "Pronto"
                  }}</span>
                </v-tooltip>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>

        <p class="text-h4 mt-15 text-center font-weight-medium cyan--text text--darken-2">
          CAPÍTULOS DISPONIBLES
        </p>
        <div v-if="sortedChapters">
          <v-card
            color="transparent"
            class="py-5 px-2"
            flat
            v-for="chap in sortedChapters"
            :key="chap.id"
          >
            <v-row>
              <v-col cols="12" sm="9">
                <v-card-text class="pa-0 text-left">
                  <p class="pa-0 mb-0 text-caption text-md-body-2 text-uppercase font-weight-bold cyan--text text--darken-2">
                    <span v-if="chap.chapter">Capítulo {{ chap.chapter }}</span> 
                    <v-chip outlined small color="cyan darken-2" v-if="(chap.chapter == 1 || chap.order == 1 || currentPlay.free) && currentPlay.available" class="mx-1" @click="playChapter(chap)">Escuchar <v-icon small>mdi-play</v-icon></v-chip>   
                  </p>
                  <p class="pa-0 mb-0 text-body-1 text-md-h6 font-weight-bold">
                    {{ chap.title }}
                  </p>
                  <p class="pa-0 mb-0 text-justify text-body-2">
                    {{ chap.summary }}
                  </p>
                </v-card-text>
              </v-col>
              <v-col cols="12" sm="3" class="mt-auto">
                <v-card-text class="pa-0 text-right">
                  <span class="pa-0 mb-0 mr-3 text-body-1 font-weight-bold" v-if="currentPlay.free">
                    Gratis
                  </span>
                  <span class="pa-0 mb-0 mr-3 text-body-1 font-weight-bold" v-else-if="currentPlay.chapterprice">
                    $ {{ currentPlay.chapterprice | toCurrency }}
                  </span>
                  <span class="pa-0 mb-0 mr-3 text-h6 font-weight-bold" v-else>
                    Pronto
                  </span>
                  <v-tooltip
                    top
                    color="cyan darken-2"
                    :disabled="currentPlay.available"
                    transition="scroll-y-reverse-transition"
                    close-delay="200"
                  >
                    <template v-slot:activator="{ on }">
                      <div v-on="on" style="display: inline-block;">
                        <v-btn
                          small
                          text
                          color="cyan darken-2"
                          @click="addProduct(chap)"
                          :disabled="!currentPlay.available || currentPlay.free"
                        >
                          <span class="d-sm-none mr-1">Comprar</span>
                          <v-icon class="mb-md-1">
                            mdi-ticket-confirmation
                          </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>
                      {{ currentPlay.release_date ? "Desde el " + currentPlay.release_date : "Pronto"}}
                    </span>
                  </v-tooltip>
                </v-card-text>
              </v-col>
            </v-row>
            <v-divider class="mt-6"></v-divider>
          </v-card>
        </div>
        <div class="my-10 text-center">
          <v-btn rounded color="cyan darken-2" small dark depressed to="/">
            Volver
          </v-btn>
        </div>
      </v-container>
    </v-main>
    <audio-player
      v-if="showPlayer"
      @close-player="closePlayer"
      :currentChapter="freeChapter"
      :showPlayer="showPlayer"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PlayCredits from '../components/SinglePlay/PlayCredits';
import PlayBanner from '../components/SinglePlay/PlayBanner'

// Helper function used to sort chapters
function compare(a, b) {
  if (a.chapter < b.chapter || a.order < b.order) return -1;
  if (a.chapter > b.chapter || a.order > b.order) return 1;
  return 0;
}

export default {
  components: {
    AudioPlayer: () => import("../components/AudioPlayer"),
    PlayCredits,
    PlayBanner
  },
  data() {
    return {
      showPlayer: false,
      freeChapter: Object,
    };
  },
  methods: {
    ...mapActions(["addToCart", "getPlays", "getPlayChapters"]),
    playChapter(chapter) {
      this.freeChapter = chapter;
      this.showPlayer = true;
    },
    closePlayer() {
      this.showPlayer = false;
      this.freeChapter = Object;
    },
    addProduct(chapter) {
      // Create a product object based on the chapter
      let product = {
        qty: 1,
        playId: this.currentPlay.id,
        id: chapter.id,
        price: this.currentPlay.chapterprice,
        title: chapter.title,
        chapter: chapter.chapter,
        isFullPLay: false,
      };
      this.addToCart(product);
    },
    addFullPlay() {
      // Create a product object based on the currentPlay
      let product = {
        qty: 1,
        playId: this.currentPlay.id,
        id: this.currentPlay.id,
        price: this.currentPlay.totalprice,
        title: this.currentPlay.name,
        chapter: "Todos",
        isFullPlay: true,
      };
      this.addToCart(product);
    },
  },
  computed: {
    ...mapState(["plays", "playChapters"]),
    sortedChapters() {
      if (this.currentPlay) {
        const chapters = this.playChapters;
        return chapters.sort(compare);
      } else {
        return null;
      }
    },
    currentPlay() {
      let route = this.$route.params.name;
      return this.plays.find((play) => {
        return play.link == route;
      });
    },
  },
  created() {
    this.getPlays().then(() => {
      this.getPlayChapters(this.currentPlay.id);
    });
    window.scrollTo({ top: 0 });
  },
};
</script>
